import './style.css';
import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { OSM, Vector as VectorSource} from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {fromLonLat,toLonLat} from 'ol/proj.js';
import Overlay from 'ol/Overlay.js';


import XYZ from 'ol/source/XYZ.js';
import { toStringHDMS } from 'ol/coordinate.js';
//import { ATTRIBUTION } from 'ol/source/OSM';




import TileJSON from 'ol/source/TileJSON.js';

//import { Group as LayerGroup, Tile as TileLayer } from 'ol/layer.js';







const source1 = new VectorSource({
    url: 'Bedele_Kebeles.geojson',
  format: new GeoJSON(),
});

const source2 = new VectorSource({
    url: 'Burqa_Kebeles.geojson',
  format: new GeoJSON(),
});

const source3 = new VectorSource({
    url: 'Upper_Debena_old.geojson',
    format: new GeoJSON(),
});

const source4 = new VectorSource({
    url: 'Upper_Debena_new.geojson',
    format: new GeoJSON(),
});

const source5 = new VectorSource({
    url: 'Hakimgara_old.geojson',
    format: new GeoJSON(),
});

const source6 = new VectorSource({
    url: 'Hakimgara_new.geojson',
    format: new GeoJSON(),
});



const addis = fromLonLat([38.7525, 9.0192]);
const Bedele_old = fromLonLat([36.44565, 8.38157]);
const Bedele_new = fromLonLat([36.3938, 8.3612]);
const Hakimgara_old = fromLonLat([42.1317, 9.28045]);
const Hakimgara_new = fromLonLat([42.18652, 9.28061]);




const container = document.getElementById('popup');
const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');



const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

const poly1 = new VectorLayer({
    source: source1,
    style: {
        'fill-color': 'rgba(200, 200, 255, 0.3)',
        'stroke-width': 1,
        'stroke-color': '#319FD3',
        /*'circle-radius': 6,
        'circle-fill-color': 'red',//rgba(255, 255, 255, 0.6)',
        'circle-stroke-width': 1,
        'circle-stroke-color': '#319FD3',*/
    },
});

const poly2 = new VectorLayer({
  source: source2,
  style: {
    'fill-color': 'rgba(200, 200, 255, 0.3)',
    'stroke-width': 1,
    'stroke-color': '#319FD3',
    /*'circle-radius': 6,
    'circle-fill-color': 'red',//rgba(255, 255, 255, 0.6)',
    'circle-stroke-width': 1,
    'circle-stroke-color': '#319FD3',*/
  },
});


const point1 = new VectorLayer({
    source: source3,
    style: {
        /*'fill-color': 'rgba(200, 200, 255, 0.3)',
        'stroke-width': 1,
        'stroke-color': '#319FD3',*/
        'circle-radius': 6,
        'circle-fill-color': 'red',//rgba(255, 255, 255, 0.6)',
        'circle-stroke-width': 1,
        'circle-stroke-color': '#319FD3',
        'z-index': 1,
    },
});

const point2 = new VectorLayer({
    source: source4,
    style: {
        /*'fill-color': 'rgba(200, 200, 255, 0.3)',
        'stroke-width': 1,
        'stroke-color': '#319FD3',*/
        'circle-radius': 6,
        'circle-fill-color': 'green',//rgba(255, 255, 255, 0.6)',
        'circle-stroke-width': 1,
        'circle-stroke-color': '#319FD3',
        'z-index': 1,
    },
});



const point3 = new VectorLayer({
    source: source5,
    style: {
        /*'fill-color': 'rgba(200, 200, 255, 0.3)',
        'stroke-width': 1,
        'stroke-color': '#319FD3',*/
        'circle-radius': 6,
        'circle-fill-color': 'red',//rgba(255, 255, 255, 0.6)',
        'circle-stroke-width': 1,
        'circle-stroke-color': '#319FD3',
        'z-index': 1,
    },
});

const point4 = new VectorLayer({
    source: source6,
    style: {
        /*'fill-color': 'rgba(200, 200, 255, 0.3)',
        'stroke-width': 1,
        'stroke-color': '#319FD3',*/
        'circle-radius': 6,
        'circle-fill-color': 'green',//rgba(255, 255, 255, 0.6)',
        'circle-stroke-width': 1,
        'circle-stroke-color': '#319FD3',
        'z-index': 1,
    },
});


const key = 'v23NIaapu5A1og1WcGwP';

/*const source0 = new ol.source.TileJSON({
    url: `https://api.maptiler.com/maps/satellite/tiles.json?key=${key}`, // source URL
    tileSize: 512,
    crossOrigin: 'anonymous'
});

*/


const view = new View({
  center: addis,
  zoom: 7,
});
const map = new Map({
    layers: [
        new TileLayer({
            source: new OSM(),
        }),
        new LayerGroup({
            layers: [
                new TileLayer({
                    source: new TileJSON({
                        url:
                            `https://api.maptiler.com/maps/satellite/tiles.json?key=${key}`,
                        crossOrigin: 'anonymous',
                    }),
                }),

                new TileLayer({
                    source: new TileJSON({
                        url:
                            'https://api.tiles.mapbox.com/v4/mapbox.world-borders-light.json?secure&access_token=' +
                            key,
                        crossOrigin: 'anonymous',
                    }),
                }),
               
            ],
        }),
               

    poly1,
    poly2,
    point1,
    point2,
    point3,
    point4,  
  ],
  target: 'map',
    view: view,
  overlays:[overlay],
});



/*
function getTimeRemaining(endtime){ let input = endtime.split("/");
  let input2 = [input[2],input[1],input[0]];
  let input3 = input2.join("-");
  const total = Date.parse(input3) - Date.parse(new Date());
  const days = Math.floor( total/(1000*60*60*24) );
if (days>=0){return days}
else if (days<0){return "License Expired!"} else {return "License non available"};
  
}*/



/*

map.on('singleclick', function (evt) {
  const coordinate = evt.coordinate;
  const hdms = toStringHDMS(toLonLat(coordinate));
  content.innerHTML = '<p>You clicked here:</p><code>' + hdms + '</code>';
  overlay.setPosition(coordinate);
});

*/
map.on("singleclick",function(evt){

  
  var feature1 = map.forEachFeatureAtPixel(evt.pixel,function(feature1,layer){
    if(layer===poly1){
      return feature1;
    }
   
  });

  var feature2 = map.forEachFeatureAtPixel(evt.pixel,function(feature2,layer){
    if(layer===poly2){
      return feature2;
    }
   
  });

  
  var feature3 = map.forEachFeatureAtPixel(evt.pixel, function (feature3, layer) {
        if (layer ===point1) {
            return feature3;
        }

    });

   var feature4 = map.forEachFeatureAtPixel(evt.pixel, function (feature4, layer) {
        if (layer === point2) {
            return feature4;
        }

    });


   var feature5 = map.forEachFeatureAtPixel(evt.pixel, function (feature5, layer) {
        if (layer === point3) {
            return feature5;
        }

    });

   var feature6 = map.forEachFeatureAtPixel(evt.pixel, function (feature6, layer) {
        if (layer === point4) {
            return feature6;
        }

    });





  if(feature1){
      var popContent = '<p>' + feature1.get('K_Name')+' Kebele</p>';
      popContent += '<p>' + feature1.get('W_Name') + ' Woreda</p>';
      popContent += '<p>Area (Ha): ' + feature1.get('Area (ha)') + '</p>';

      
content.innerHTML=popContent;
const coordinate = evt.coordinate;
overlay.setPosition(coordinate);

}

if(feature2){
    var popContent = '<p>' + feature2.get('K_Name') + ' Kebele</p>';
    popContent += '<p>' + feature2.get('W_Name') + ' Woreda</p>';
    popContent += '<p>Area (Ha): ' + feature2.get('Area (ha)') + '</p>';
    
  
  
  
  
  content.innerHTML=popContent;
  const coordinate = evt.coordinate;
  overlay.setPosition(coordinate);
  
  }
 

if(feature3){
  var popContent = '<p>' + feature3.get('Kebele') + ' Kebele</p>';
  popContent += '<p>Sample Code: ' + feature3.get('Sample_cod')+'</p>';
  popContent += '<p>Longitude: ' + feature3.get('Longitude')+'</p>';
  popContent += '<p>Latitude: ' + feature3.get('Latitude')+'</p>';
  popContent += '<p>WGS84-UTMZ37X: ' + feature3.get('W84-UTM37X')+'</p>';
  popContent += '<p>WGS84-UTMZ37Y: ' + feature3.get('W84-UTM37Y')+'</p>';
  
  
  
  
  
  content.innerHTML=popContent;
  const coordinate = evt.coordinate;
  overlay.setPosition(coordinate);
  
  }

if (feature4) {
  var popContent = '<p>' + feature4.get('Kebele') + ' Kebele</p>';
  popContent += '<p>Sample Code: ' + feature4.get('Sample_cod') + '</p>';
  popContent += '<p>Longitude: ' + feature4.get('Longitude') + '</p>';
  popContent += '<p>Latitude: ' + feature4.get('Latitude') + '</p>';
  popContent += '<p>WGS84-UTMZ37X: ' + feature4.get('W84-UTM37X') + '</p>';
  popContent += '<p>WGS84-UTMZ37Y: ' + feature4.get('W84-UTM37Y') + '</p>';





 content.innerHTML = popContent;
 const coordinate = evt.coordinate;
 overlay.setPosition(coordinate);

    }





  
 if (feature5) {
     var popContent = '<p>' + feature5.get('Kebele') + ' Kebele</p>';
     popContent += '<p>Sample Code: ' + feature5.get('Sample_cod') + '</p>';
     popContent += '<p>Longitude: ' + feature5.get('Longitude') + '</p>';
     popContent += '<p>Latitude: ' + feature5.get('Latitude') + '</p>';
     popContent += '<p>WGS84-UTMZ38X: ' + feature5.get('W84-UTM38X') + '</p>';
     popContent += '<p>WGS84-UTMZ38Y: ' + feature5.get('W84-UTM38Y') + '</p>';




  content.innerHTML = popContent;
  const coordinate = evt.coordinate;
  overlay.setPosition(coordinate);

    }

 
 if (feature5) {
     var popContent = '<p>' + feature5.get('Kebele') + ' Kebele</p>';
     popContent += '<p>Sample Code: ' + feature5.get('Sample_cod') + '</p>';
     popContent += '<p>Longitude: ' + feature5.get('Longitude') + '</p>';
     popContent += '<p>Latitude: ' + feature5.get('Latitude') + '</p>';
     popContent += '<p>WGS84-UTMZ38X: ' + feature5.get('W84-UTM38X') + '</p>';
     popContent += '<p>WGS84-UTMZ38Y: ' + feature5.get('W84-UTM38Y') + '</p>';




  content.innerHTML = popContent;
  const coordinate = evt.coordinate;
  overlay.setPosition(coordinate);

    }
 if (feature6) {
    var popContent = '<p>' + feature6.get('Kebele') + ' Kebele</p>';
    popContent += '<p>Sample Code: ' + feature6.get('Sample_cod') + '</p>';
    popContent += '<p>Longitude: ' + feature6.get('Longitude') + '</p>';
    popContent += '<p>Latitude: ' + feature6.get('Latitude') + '</p>';
    popContent += '<p>WGS84-UTMZ38X: ' + feature6.get('W84-UTM38X') + '</p>';
    popContent += '<p>WGS84-UTMZ38Y: ' + feature6.get('W84-UTM38Y') + '</p>';




   content.innerHTML = popContent;
   const coordinate = evt.coordinate;
   overlay.setPosition(coordinate);

    }

    

})

closer.onClick = function(){

overlay.setPosition(undefined);
}



map.on('click', function (evt) {
    const coordinate = evt.coordinate;
    const hdms = toStringHDMS(toLonLat(coordinate));
    content.innerHTML = '<p>You clicked here:</p><code>' + hdms + '</code>';
    overlay.setPosition(coordinate);
});







function onClick(id, callback) {
  document.getElementById(id).addEventListener('click', callback);
}

onClick('btn1', function () {
  view.animate({
      center: Bedele_old,
    duration: 2000,
    zoom:14
  });
});

onClick('btn2', function () {
  view.animate({
      center: Bedele_new,
    duration: 2000,
    zoom:13.5
  });
});

onClick('btn3', function () {
  view.animate({
    center: Hakimgara_old,
    duration: 2000,
    zoom:14.5
  });
});

onClick('btn4', function () {
  view.animate({
    center: Hakimgara_new,
    duration: 2000,
    zoom:14.5
  });
});













// change mouse cursor when over marker
map.on('pointermove', function (e) {
  const pixel = map.getEventPixel(e.originalEvent);
  const hit = map.hasFeatureAtPixel(pixel);
 document.getElementById(map.getTarget()).style.cursor = hit ? 'pointer' : '';
});

function bindInputs(layerid, layer) {
    const visibilityInput = $(layerid + ' input.visible');
    visibilityInput.on('change', function () {
        layer.setVisible(this.checked);
    });
    visibilityInput.prop('checked', layer.getVisible());
        
}
function setup(id, group) {
    group.getLayers().forEach(function (layer, i) {
        const layerid = id + i;
        bindInputs(layerid, layer);
        if (layer instanceof LayerGroup) {
            setup(layerid, layer);
        }
    });
}
setup('#layer', map.getLayerGroup());

$('#layertree li > span')
    .click(function () {
        $(this).siblings('fieldset').toggle();
    })
    .siblings('fieldset')
    .hide();

